<template>
    <div>
        <v-card flat style="background-color: var(--v-background-base) !important">
            <v-toolbar :color="$store.state.darkMode?'greyBase':'indigo'" dark :extended="!$vuetify.breakpoint.mobile" flat extension-height="55">
                <v-toolbar-title>
                    <b class="mr-3">PPECB</b>
                </v-toolbar-title>
            </v-toolbar>
            <v-card class="mx-auto" :max-width="!$vuetify.breakpoint.mobile ? '94vw' : '100%'" :style="{
                'margin-top': !$vuetify.breakpoint.mobile ? '-64px' : '0px',
                'border-radius': !$vuetify.breakpoint.mobile ? '50px' : '0px',
            }">
                <v-card-text :style="{
                    height: !$vuetify.breakpoint.mobile ? '90vh' : '90vh',
                    'overflow-y': 'auto',
                }">
                    <v-tabs show-arrows v-model="activeTab" height="40px" class="mb-1 pb-1 mt-0 pt-0">
                        <v-tab v-for="tab in tabs" :key="tab.value" :href="`#${tab.name}`" @click="activeTab != `${tab.value}` ? $router.push({ hash:`#${tab.value}` }) : ''">
                            {{ tab.name }}
                        </v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="activeTab">
                        <v-tab-item value="Dashboard">
                            <Dashboard />
                        </v-tab-item>

                        <v-tab-item value="Tasks">
                            <v-card flat >
                                <v-card-text>
                                    <Task />
                                </v-card-text>
                            </v-card>
                        </v-tab-item>
                    </v-tabs-items>
                </v-card-text>
            </v-card>
        </v-card>
    </div>
</template>
<script>
import Dashboard from '@/components/PPECB/Dashboard.vue'
import Task from '@/components/PPECB/Task.vue'

export default {
    components: {
        Dashboard,
        Task
    },
    data: () => ({
        activeTab: 0,
        loaded: true,
        tabs: [
      { name: "Dashboard", value: "dashboard" },
      { name: "Tasks", value: "tasks" },
    ],
    }),
    mounted() {
        if (window.location.hash) {
            let component = decodeURI(window.location.hash).split('#')[1];
            this.activeTab = component
        } else {
            this.$router.push({
                hash: '#dashboard'
            })
        }
    }
}
</script>